import React, { useState, useImperativeHandle, forwardRef } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls } from "../scripts/contractApi.js";

const RegisterAtManager = forwardRef(({ address, registeredAtManager }, ref) => {
  const [description, setDescription] = useState("A descriptive message for the contract in the manager");
  const [step, setStep] = useState(false);
  const [location, setLocation] = useState(null);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleAdressChange = (e) => {
    setLocation(e.target.value);
  };
  const handleButtonClick = async () => {
    let result = await Manager.addDeploymentToManager(address,description);
    if(result){
      setStep(true);
      registeredAtManager();
    }
  };

  useImperativeHandle(ref, () => ({
    reset() {
      setStep(false);
      setDescription("A descriptive message for the contract in the manager")
    },
  }));


  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Register At Manager</p>
      <p className="DeploymentStep-inputfield-title">Address (generated in the last step)</p>
      <input type="text" className="DeploymentStep-input" value={address} onChange={handleAdressChange}/>
      <p className="DeploymentStep-inputfield-title">Description</p>
      <input type="text" className="DeploymentStep-input" value={description} onChange={handleDescriptionChange} />
      <button className="StepButton" onClick={handleButtonClick}>
         {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
})

export default RegisterAtManager;
