import React, { useState, useImperativeHandle, forwardRef } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls } from "../scripts/contractApi.js";

const DeploymentStep = forwardRef(({ title, onAddressCreated }, ref) => {
  const [name, setName] = useState("The Gainlings Season 3");
  const [tag, setTag] = useState("TGS");
  const [description, setDescription] = useState("This is a factory and manager test contract");
  const [coordinatorAddress, setCoordinatorAddress] = useState("0x50d47e4142598E3411aA864e08a44284e471AC6f");
  const [keyhash, setKeyhash] = useState("0x027f94ff1465b3525f9fc03e9ff7d6d2c0953482246dd6ae07570c45d6631414");
  const [subscriptionId, setSubscriptionId] = useState("138");
  const [step, setStep] = useState(false);

  useImperativeHandle(ref, () => ({
    reset() {
      setName("The Gainlings Season 3");
      setTag("TGS");
      setDescription("This is a factory and manager test contract");
      setCoordinatorAddress("0x50d47e4142598E3411aA864e08a44284e471AC6f");
      setKeyhash("0x027f94ff1465b3525f9fc03e9ff7d6d2c0953482246dd6ae07570c45d6631414");
      setSubscriptionId("138");
      setStep(false);
    },
  }));

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleTagChange = (e) => {
    setTag(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleCoordinatorChange = (e) => {
    setCoordinatorAddress(e.target.value);
  };
  const handleKeyhashChange = (e) => {
    setKeyhash(e.target.value);
  };
  const handleSubscriptionIdChange = (e) => {
    setSubscriptionId(e.target.value);
  };

  const handleButtonClick = async () => {
    try {
      var address = await Factory.createNewGainlingsContractWithAddress(name, tag, coordinatorAddress, subscriptionId, keyhash);
      if (address) {
        setStep(true);
        onAddressCreated(address);
      }
    } catch (error) {}
  };

  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Deploy Contract</p>
      <p className="DeploymentStep-inputfield-title">Name (The-Gainlings-#)</p>
      <input type="text" className="DeploymentStep-input" value={name} onChange={handleNameChange} />
      <p className="DeploymentStep-inputfield-title">Tag</p>
      <input type="text" className="DeploymentStep-input" value={tag} onChange={handleTagChange} />
      <p className="DeploymentStep-inputfield-title">Description</p>
      <input type="text" className="DeploymentStep-input" value={description} onChange={handleDescriptionChange} />
      <p className="DeploymentStep-inputfield-title">VRF Coordinator Address</p>
      <input type="text" className="DeploymentStep-input" value={coordinatorAddress} onChange={handleCoordinatorChange} />
      <p className="DeploymentStep-inputfield-title">VRF Keyhash</p>
      <input type="text" className="DeploymentStep-input" value={keyhash} onChange={handleKeyhashChange} />
      <p className="DeploymentStep-inputfield-title">VRF Subscription ID</p>
      <input type="text" className="DeploymentStep-input" value={subscriptionId} onChange={handleSubscriptionIdChange} />
      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default DeploymentStep;
