import * as ethers from "ethers";
import { factoryAbi } from "./factoryAbi.js";
import { managerAbi } from "./managerAbi.js";
import { tokenAbi } from "./tokenAbi.js";
import { trophyAbi } from "./trophyAbi.js";
import { vrfCoordinatorAbi } from "./vrfCoordinatorAbi.js";

const FACTORY_ADDRESS_TESTNET = "0x507FDa6e7A7bed88F6b6cd0DEA0f042a66046658";
const MANAGER_ADDRESS_TESTNET = "0x771b10AC9585452de9140750b9ffA13BeB8c1b7D";
let contracts = {};

export const connectWallet = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  contracts.signer = signer;
  contracts.factory = new ethers.Contract(FACTORY_ADDRESS_TESTNET, factoryAbi.abi, signer);
  contracts.manager = new ethers.Contract(MANAGER_ADDRESS_TESTNET, managerAbi.abi, signer);
  if ((signer == undefined) | null) {
    return false;
  }
  return true;
};

export const Multicalls = {
  async createNewGainlingsContractAndAddToManager(name, tag, description, vrfCoordinator, subscriptionId, keyHash) {
    if (!(await connectWallet())) return;
    try {
      const deploymentReceipt = await Factory.createNewGainlingsContract(name, tag, vrfCoordinator, subscriptionId, keyHash);
      console.log(JSON.stringify(deploymentReceipt));

      // Find the OwnershipTransferred event
      const ownershipTransferredEvent = deploymentReceipt.events.find((event) => event.event === "OwnershipTransferred");

      // Extract the contract address from the 'address' field of the event
      const address = ownershipTransferredEvent ? ownershipTransferredEvent.address : null;

      if (!address) {
        throw new Error("Contract address not found in the transaction receipt");
      }

      const addResult = await Manager.addDeploymentToManager(address, description);
      return addResult;
    } catch (error) {
      console.error("Error in createNewGainlingsContractAndAddToManager:", error);
      throw error;
    }
  },
};
export const Factory = {
  async createNewGainlingsContract(name, tag, vrfCoordinator, subscriptionId, keyHash) {
    if (!(await connectWallet())) return;
    try {
      const transaction = await contracts.factory.createContract(name, tag, subscriptionId, vrfCoordinator, keyHash);
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error creating new Gainlings contract:", error);
      throw error;
    }
  },
  async createNewGainlingsContractWithAddress(name, tag, vrfCoordinator, subscriptionId, keyHash) {
    if (!(await connectWallet())) return;
    try {
      const deploymentReceipt = await Factory.createNewGainlingsContract(name, tag, vrfCoordinator, subscriptionId, keyHash);

      // Find the OwnershipTransferred event
      const ownershipTransferredEvent = deploymentReceipt.events.find((event) => event.event === "OwnershipTransferred");

      // Extract the contract address from the 'address' field of the event
      const address = ownershipTransferredEvent ? ownershipTransferredEvent.address : null;

      if (!address) {
        throw new Error("Contract address not found in the transaction receipt");
      }
      return address; // Return the transaction receipt
    } catch (error) {
      console.error("Error creating new Gainlings contract:", error);
      throw error;
    }
  },
};
export const Manager = {
  async getDeploymentInfo() {
    if (!(await connectWallet())) return;
    try {
      const result = await contracts.manager.Deployments(0);
      console.log(result);
      return result;
    } catch (error) {
      console.error("Error fetching deployment info:", error);
      throw error;
    }
  },
  async getPagedDeployments(page, pagesize) {
    if (!(await connectWallet())) return;
    try {
      const result = await contracts.manager.getPagedDeployments(page, pagesize);
      return result;
    } catch (error) {
      console.error("Error fetching deployment info:", error);
      throw error;
    }
  },
  async addDeploymentToManager(location, description) {
    if (!(await connectWallet())) return;
    try {
      const transaction = await contracts.manager.addDeployment(location, description);
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error adding deployment to manager:", error);
      throw error;
    }
  },
  async getLatestDeploymentAddress() {
    if (!(await connectWallet())) return;
    try {
      const result = await contracts.manager.getLatestDeploymentLocation();
      console.log(result);
      return result;
    } catch (error) {
      console.error("Error fetching deployment info:", error);
      throw error;
    }
  },
};
export const Token = {
  async setMerkleProof(contractAddress, merkleProof) {
    try {
      if (!(await connectWallet())) return;
      const contract = new ethers.Contract(contractAddress, tokenAbi.abi, contracts.signer);
      const transaction = await contract.setMerkleProof(merkleProof);
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error adding deployment to manager:", error);
      throw error;
    }
  },
  async setContractParams(
    contractAddress,
    gainlingsStorageContractAddress,
    libraryContractAddress,
    trophyFactoryContractAddress,
    marketContractAddress,
    animationUrl,
    pricePerToken
  ) {
    try {
      if (!(await connectWallet())) return;
      const contract = new ethers.Contract(contractAddress, tokenAbi.abi, contracts.signer);
      const transaction = await contract.setContractParams(
        gainlingsStorageContractAddress,
        libraryContractAddress,
        trophyFactoryContractAddress,
        marketContractAddress,
        animationUrl,
        pricePerToken
      );
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error setContractParams", error);
      throw error;
    }
  },
  async setContractParamsAdvanced(
    contractAddress,
    weightMultiplier,
    weightDivider,
    cooldownBuff,
    mintsPerWallet,
    mintsPerTx
  ) {
    try {
      if (!(await connectWallet())) return;
      const contract = new ethers.Contract(contractAddress, tokenAbi.abi, contracts.signer);
      const transaction = await contract.setContractParamsAdvanced(
        weightMultiplier,
        weightDivider,
        cooldownBuff,
        mintsPerWallet,
        mintsPerTx
      );
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error setContractParamsAdvanced:", error);
      throw error;
    }
  },
  async setMintTime(contractAddress, mintTime, mintLenght, prepLength) {
    try {
      if (!(await connectWallet())) return;
      const contract = new ethers.Contract(contractAddress, tokenAbi.abi, contracts.signer);
      const transaction = await contract.setMintTime(mintTime, mintLenght, prepLength);
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error adding deployment to manager:", error);
      throw error;
    }
  },
};
export const Trophies = {
  async setMerkleRoot(contractAddress, merkleRoot) {
    try {
      if (!(await connectWallet())) return;
      const contract = new ethers.Contract(contractAddress, trophyAbi.abi, contracts.signer);
      const transaction = await contract.setMerkleRoot(merkleRoot);
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error adding deployment to manager:", error);
      throw error;
    }
  },
};
export const VRF = {
  async addConsumer(vrfCoordinatorAddress, subscriptionId, consumerAddress) {
    try {
      if (!(await connectWallet())) return;
      const contract = new ethers.Contract(vrfCoordinatorAddress, vrfCoordinatorAbi.abi, contracts.signer);
      const transaction = await contract.addConsumer(subscriptionId, consumerAddress);
      const receipt = await transaction.wait();
      return receipt; // Return the transaction receipt
    } catch (error) {
      console.error("Error adding consumer to coordinator:", error);
      throw error;
    }
  },
};
