import { MerkleTree as MerkleTreeJS } from 'merkletreejs';
import keccak256 from 'keccak256';

window.Buffer = window.Buffer || require("buffer").Buffer;

export const MerkleTree = {
  

  async buildMerkleTree(adds, callback) {
    const leafNodes = adds.map(a => a.toLowerCase()).map(keccak256);
    const merkleTree = new MerkleTreeJS(leafNodes, keccak256, { sortPairs: true });
    callback(merkleTree);
  },

  async buildMerkleTreeAwaitable(adds) {
    try {
      adds = adds.split(",");
      const leafNodes = adds.map(a => a.toLowerCase()).map(keccak256);
      const merkleTree = new MerkleTreeJS(leafNodes, keccak256, { sortPairs: true });
      return merkleTree;
    } catch (err) {
      throw err;
    }
  },
  async buildMerkleTreeAwaitableFromArray(adds) {
    try {
      const leafNodes = adds.map(a => a.toLowerCase()).map(keccak256);
      const merkleTree = new MerkleTreeJS(leafNodes, keccak256, { sortPairs: true });
      return merkleTree;
    } catch (err) {
      throw err;
    }
  }
}


