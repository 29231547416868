import React, { useState, useImperativeHandle, forwardRef } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls, Trophies, Token } from "../scripts/contractApi.js";
import { defaults } from "../constants/defaultValues.js";

const MintTime = forwardRef(({ onMintTimeSet, deployedAddress }, ref) => {
  const [step, setStep] = useState(false);
  const [mintTime, setMintTime] = useState(new Date(defaults.MINT_TIME).getTime() / 1000);
  const [mintLenght, setMintLength] = useState(Number(defaults.MINT_LENGTH));
  const [prepLength, setPrepLength] = useState(Number(defaults.PREP_LENGTH));

  useImperativeHandle(ref, () => ({
    reset() {
      setStep(false);
    },
  }));

  const handleMintTimeChange = (e) => {
    setMintTime(e.target.value);
  };
  const handleMintLengthChange = (e) => {
    setMintLength(e.target.value);
  };
  const handlePrepLengthChange = (e) => {
    setPrepLength(e.target.value);
  };


  const handleButtonClick = async () => {
    try {
      const result = await Token.setMintTime(
        deployedAddress,
        mintTime,
        mintLenght,
        prepLength,
      );
      if (result) {
        onMintTimeSet();
        setStep(true);
      }
    } catch (error) {}
  };

  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Set Mint Time</p>

      <p className="DeploymentStep-inputfield-title">Mint Time</p>
      <input type="text" className="DeploymentStep-input" value={mintTime} onChange={handleMintTimeChange} />

      <p className="DeploymentStep-inputfield-title">Mint Length [seconds]</p>
      <input type="text" className="DeploymentStep-input" value={mintLenght} onChange={handleMintLengthChange} />

      <p className="DeploymentStep-inputfield-title">Time between Mint and Fight [seconds]</p>
      <input type="text" className="DeploymentStep-input" value={prepLength} onChange={handlePrepLengthChange} />


      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default MintTime;
