import React, { useState, useImperativeHandle, forwardRef } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import {
  connectWallet,
  Manager,
  Factory,
  Multicalls,
  Trophies,
  Token,
} from "../scripts/contractApi.js";
import { defaults } from "../constants/defaultValues.js";

const ContractParams = forwardRef(
  ({ onContractParamsSet, deployedAddress }, ref) => {
    const [step, setStep] = useState(false);
    const [
      gainlingsStorageContractAddress,
      setGainlingsStorageContractAddress,
    ] = useState("0xe48f16f3d5C3dB9B769143fe6C6960B073C337f0");
    const [libraryContractAddress, setLibraryContractAddress] = useState(
      "0x84260733129c580181B2f6eD812F5C322a3Ad250"
    );
    const [trophyFactoryContractAddress, setTrophyFactoryContractAddress] =
      useState("0x7c71bdC39FEbE25684ACfDE7fFaa9BAf20b91A35");
    const [marketContractAddress, setMarketContractAddress] = useState(
      "0x7F42322Fb6F580136952fE1bF3438a9694B05a29"
    );
    const [animationUrl, setAnimationUrl] = useState(defaults.ANIMATION_URL);
    const [pricePerToken, setPricePerToken] = useState(
      defaults.PRICE_PER_TOKEN
    );

    useImperativeHandle(ref, () => ({
      reset() {
        setStep(false);
      },
    }));

    const handleGainlingsStorageContractAddressChange = (e) => {
      setGainlingsStorageContractAddress(e.target.value);
    };
    const handleLibraryContractAddressChange = (e) => {
      setLibraryContractAddress(e.target.value);
    };
    const handleTrophyFactoryContractAddressChange = (e) => {
      setTrophyFactoryContractAddress(e.target.value);
    };
    const handleMarketContractAddressChange = (e) => {
      setMarketContractAddress(e.target.value);
    };
    const handleAnimationUrlChange = (e) => {
      setAnimationUrl(e.target.value);
    };
    const handlePricePerTokenChange = (e) => {
      setPricePerToken(e.target.value);
    };

    const handleButtonClick = async () => {
      try {
        const result = await Token.setContractParams(
          deployedAddress,
          gainlingsStorageContractAddress,
          libraryContractAddress,
          trophyFactoryContractAddress,
          marketContractAddress,
          animationUrl,
          pricePerToken
        );
        if (result) {
          onContractParamsSet();
          setStep(true);
        }
      } catch (error) {}
    };

    return (
      <div className="DeploymentStep-container">
        <p className="DeploymentStep-title">Set Contract Params</p>

        <p className="DeploymentStep-inputfield-title">
          Gainlings Storage Contract Address
        </p>
        <input
          type="text"
          className="DeploymentStep-input"
          value={gainlingsStorageContractAddress}
          onChange={handleGainlingsStorageContractAddressChange}
        />

        <p className="DeploymentStep-inputfield-title">
          Gainlings Library Contract Address
        </p>
        <input
          type="text"
          className="DeploymentStep-input"
          value={libraryContractAddress}
          onChange={handleLibraryContractAddressChange}
        />

        <p className="DeploymentStep-inputfield-title">
          Trophy Factory Contract Address
        </p>
        <input
          type="text"
          className="DeploymentStep-input"
          value={trophyFactoryContractAddress}
          onChange={handleTrophyFactoryContractAddressChange}
        />

        <p className="DeploymentStep-inputfield-title">
          Market Contract Address
        </p>
        <input
          type="text"
          className="DeploymentStep-input"
          value={marketContractAddress}
          onChange={handleMarketContractAddressChange}
        />

        <p className="DeploymentStep-inputfield-title">Animation Url</p>
        <input
          type="text"
          className="DeploymentStep-input"
          value={animationUrl}
          onChange={handleAnimationUrlChange}
        />

        <p className="DeploymentStep-inputfield-title">Price Per Gainlings</p>
        <input
          type="text"
          className="DeploymentStep-input"
          value={pricePerToken}
          onChange={handlePricePerTokenChange}
        />

        <button className="StepButton" onClick={handleButtonClick}>
          {`Send tx ${step ? "✅" : ""}`}
        </button>
      </div>
    );
  }
);

export default ContractParams;
