import React, { useState, useImperativeHandle, forwardRef } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls, Trophies } from "../scripts/contractApi.js";

const MerkleRoot = forwardRef(({ root, onMerkleRootSet }, ref) => {
  const [step, setStep] = useState(false);
  const [trophyContractAddress, setTrophyContractAddress] = useState("0x7c71bdC39FEbE25684ACfDE7fFaa9BAf20b91A35");

  useImperativeHandle(ref, () => ({
    reset() {
      setStep(false);
    },
  }));
  const handleTrophyContractAddressChange = (e) => {
    setTrophyContractAddress(e.target.value);
  };
  const handleButtonClick = async () => {
    try {
      var result = await Trophies.setMerkleRoot(trophyContractAddress, root);
      if (result.status) {
        onMerkleRootSet();
        setStep(true);
      }
    } catch (error) {}
  };

  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Set new merkle root</p>
      <p className="DeploymentStep-inputfield-title">Root (generated in the last step)</p>
      <input type="text" className="DeploymentStep-input" disabled={true} value={root} />
      <p className="DeploymentStep-inputfield-title">Trophy Contract Address</p>
      <input type="text" className="DeploymentStep-input" value={trophyContractAddress} onChange={handleTrophyContractAddressChange} />
      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default MerkleRoot;
