export const defaults = {
    //COMMON
    ANIMATION_URL: "https://hidden-frog-6004.on.fleek.co/",
    //TESTNET
    PRICE_PER_TOKEN: "10000000000000000",
    MINT_TIME: "2024-03-23T17:15:00", 
    MINT_LENGTH: "1800", 
    PREP_LENGTH: "300", 
    VRF_COORDINATOR_ADDRESS: "0x50d47e4142598E3411aA864e08a44284e471AC6f",
    SUBSCRIPTION_ID: "138"
}